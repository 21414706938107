import { gql } from '@apollo/client';
import { CheckoutPageFragment } from './checkoutPageFragments.gql';
import { OrderConfirmationPageFragment } from './OrderConfirmationPage/orderConfirmationPageFragments.gql';

export const CREATE_CART = gql`
  # This mutation will return a masked cart id. If a bearer token is provided for
  # a logged in user it will return the cart id for that user.
  mutation createCart {
    cartId: createEmptyCart
  }
`;

export const UPDATE_TRACKING = gql`
  mutation updateTracking($input: GclidOutput) {
    updateGclid(input: $input) {
      id
    }
  }
`;
export const PLACE_ORDER = gql`
  mutation placeOrder($cartId: String!) {
    placeOrder(input: { cart_id: $cartId }) {
      order {
        order_number
      }
    }
  }
`;

// A query to fetch order details _right_ before we submit, so that we can pass
// data to the order confirmation page.
export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      ...OrderConfirmationPageFragment
    }
  }
  ${OrderConfirmationPageFragment}
`;

export const GET_AVAILABLE_PAYMENT_METHOD = gql`
  query getAvailablePaymentMethod($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      available_payment_methods {
        code
        title
      }
    }
  }
`;

export const GET_CHECKOUT_DETAILS = gql`
  query getCheckoutDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      ...CheckoutPageFragment
    }
  }
  ${CheckoutPageFragment}
`;

export const GET_CUSTOMER = gql`
  query GetCustomerForCheckout {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      default_shipping
      firstname
      email
      lastname
    }
  }
`;

export const UPDATE_CART_REQUEST_CALLBACK = gql`
  mutation updateCartRequestCallback(
    $cartId: String!
    $remove: Boolean!
    $callback: Int!
  ) {
    updateCartRequestCallback(
      input: { cart_id: $cartId, remove: $remove, callback: $callback }
    ) {
      callback
    }
  }
`;

export const MAGIC_CHECKOUT_PLACE_ORDER = gql`
  mutation placeOrder(
    $cart_id: String!
    $razorpay_order_id: String!
    $razorpay_payment_id: String!
    $razorpay_signature: String!
    $utm_source: String!
    $utm_campaign: String!
    $utm_medium: String!
    $gclid: String!
  ) {
    convertCartToOrder(
      input: {
        cart_id: $cart_id
        razorpay_order_id: $razorpay_order_id
        razorpay_payment_id: $razorpay_payment_id
        razorpay_signature: $razorpay_signature
        utm_source: $utm_source 
        utm_campaign: $utm_campaign
        utm_medium: $utm_medium
        gclid: $gclid
      }
    ) {
      currency
      message
      order_id
      order_increment_id
      success
    }
  }
`;

export default {
  createCartMutation: CREATE_CART,
  getCheckoutDetailsQuery: GET_CHECKOUT_DETAILS,
  getCustomerQuery: GET_CUSTOMER,
  getOrderDetailsQuery: GET_ORDER_DETAILS,
  placeOrderMutation: PLACE_ORDER,
  updateTrackingMutation: UPDATE_TRACKING,
  updateCartRequestCallback: UPDATE_CART_REQUEST_CALLBACK,
};
