import { useMutation } from '@apollo/client';
import { UPDATE_TRACKING } from './CheckoutPage/checkoutPage.gql';
import { useCallback } from 'react';

const TRACKING_PARAMS_LIST = [
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
];

const useUpdateUtmParams = () => {
  const [updateTracking, { loading: updateTrackingLoading }] =
    useMutation(UPDATE_TRACKING);

  const updateUTMTracking = useCallback(
    cartId => {
      if (!cartId) return null;

      try {
        const utmParams = TRACKING_PARAMS_LIST.reduce((acc, key) => {
          acc[key] = localStorage.getItem(`lt_${key}`) || '';
          return acc;
        }, {});

        let shouldUpdate = false;
        let hasNonEmptyValue = false;

        // here JSON.parse will not break as in fallback values is provided
        const prevUtmParams =
          !shouldUpdate &&
          JSON.parse(localStorage.getItem('prev_utm_params') || '{}') || {};

        TRACKING_PARAMS_LIST.forEach(key => {
          if (utmParams[key] !== prevUtmParams[key] && cartId) {
            shouldUpdate = true;
          }
          if (utmParams[key]) {
            hasNonEmptyValue = true;
          }
        });
        
        if (shouldUpdate && hasNonEmptyValue && cartId) {
          updateTracking({
            variables: {
              input: {
                cart_id: cartId,
                ...utmParams,
                remove: false,
              },
            },
            errorPolicy: 'ignore',
            fetchPolicy: 'no-cache',
          }).then(() => {
            localStorage.setItem('prev_utm_params', JSON.stringify(utmParams));
          });
        }
      } catch (e) {
        console.log('Error while updating utm params');
      }
    },
    [updateTracking],
  );

  return {
    updateUTMTracking,
  };
};

export default useUpdateUtmParams;
